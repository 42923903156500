import styled from "styled-components";

interface Props {
  width?: string;
  padding?: string;
  margin?: string;
}

export const Select = styled.select`
  min-height: 38px;
  min-width: 140px;
  width: ${(props: Props) => (props.width ? props.width : "auto")};
  padding: ${(props: Props) => (props.padding ? props.padding : "0 8px")};
  margin: ${(props: Props) => (props.margin ? props.margin : "0")};
  background: #ffffff;
  border: solid 1px black;
  border-radius: 10px;
  cursor: pointer;
  :focus {
    border: solid 1px black;
    outline: none;
  }
  option {
    min-height: 38px;
    min-width: 100px;
    padding: 0 8px;
    background: #ffffff;
  }
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 7L13.0622 0.205882H0.937822L7 7Z' fill='%235A5A5A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;

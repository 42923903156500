import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DropDown } from "../types";

interface ListProps {
  title: string;
  items: DropDown[];
  selectedItem?: DropDown;
  height?: string;
  onSelect?(selectedItem: DropDown): void;
}

export const List = (props: ListProps) => {
  const [selected, setSelected] = useState<DropDown>(
    props.selectedItem || ({} as DropDown)
  );
  useEffect(() => props.selectedItem && setSelected(props.selectedItem), [
    props.selectedItem,
  ]);

  return (
    <Container height={props.height}>
      <Header>{props.title}</Header>
      <ItemsContainer>
        {props.items.length > 0 ? (
          props.items.map((item: DropDown, key: number) => (
            <ListItem
              key={String(key)}
              onClick={() => {
                setSelected(item);
                if (props.onSelect) {
                  props.onSelect(item);
                }
              }}
              className={`${
                String(selected.ID) === String(item.ID) ? "selected" : ""
              }`}
            >
              <p>{item.Title}</p>
            </ListItem>
          ))
        ) : (
          <EmptyList>No Item has been found</EmptyList>
        )}
      </ItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 200px;
  height: ${(props: { height?: string }) => props.height || "100%"};
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 42px;
  padding-left: 16px;
  background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%), #000000;
  align-items: center;
  display: flex;
`;

const ItemsContainer = styled.div`
  background-color: white;
  overflow-y: auto;
  min-height: 100px;
  height: calc(100% - 42px);
`;

const EmptyList = styled.p`
  color: grey;
  width: 100%;
  text-align: center;
`;
const ListItem = styled.div`
  padding: 2px 16px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #2e2e2e;
    &::before {
      content: ">";
      font-family: SimSun;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: -0.01em;
      color: #2e2e2e;
      margin-right: 8px;
    }
  }
  cursor: pointer;

  :hover {
    background-color: #0f934938;
  }
  &.selected {
    background-color: #0f9349;
    p {
      color: white;
    }
  }
`;

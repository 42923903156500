import React from 'react'
import { TextField, MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { SupportSchema } from './Schema'
import styled from 'styled-components'
import {
  Form,
  FormContent,
  StyledFormControl,
  SaveButton,
  Wrapper,
  RowContainer,
  Header,
  SuccessAlert,
  Alert,
  ErrorAlert
} from '@agxio/ui-kit'
import { SupportFormSource, SupportForm } from './types'

interface Props {
  supportUrl: string
  apiKey: string
  fromSource: SupportFormSource
  completionMessage?: string
  companyName: string
  firstName?: string
  lastName?: string
  email?: string
}

const loginTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#056e34'
    },
    secondary: {
      main: '#000'
    },
    background: {
      paper: '#FFF'
    }
  },
  typography: {}
})

export default (props: Props) => {
  return (
    <FormContainer>
      <Header>Our Support Hotline Form:</Header>
      <MuiThemeProvider theme={loginTheme}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: props.firstName || '',
            lastName: props.lastName || '',
            companyName: props.companyName || '',
            email: props.email || '',
            message: ''
          }}
          onSubmit={async (values: SupportForm, { resetForm }) => {
            fetch(props.supportUrl, {
              method: 'POST',
              body: JSON.stringify({ ...values, from: props.fromSource }),
              headers: {
                Authorization: `Bearer ${props.apiKey}`,
                'Content-Type': 'application/json'
              }
            })
              .then(() => {
                resetForm()
                SuccessAlert(
                  props.completionMessage ||
                    'Thank You For Your Request, A Member Of The Team Will Contact You Shortly'
                )
              })
              .catch((e) => {
                console.log(e)
                resetForm()
                ErrorAlert('Error')
              })
            return true
          }}
          validationSchema={SupportSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit
          }: FormikProps<any>) => {
            return (
              <Form id='form' onSubmit={handleSubmit}>
                <FormContent>
                  <Wrapper margin='0 0 1% 0'>
                    <RowContainer
                      width='100%'
                      justify='space-between'
                      align='center'
                    >
                      <StyledFormControl fullWidth>
                        <TextField
                          error={touched.firstName && !!errors.firstName}
                          name={'firstName'}
                          label={'First Name'}
                          value={values.firstName || ''}
                          onChange={handleChange}
                          color='secondary'
                          type='text'
                          variant='outlined'
                        />
                      </StyledFormControl>
                      <StyledFormControl fullWidth>
                        <TextField
                          error={touched.lastName && !!errors.lastName}
                          name={'lastName'}
                          label={'Last Name'}
                          value={values.lastName || ''}
                          onChange={handleChange}
                          color='secondary'
                          type='text'
                          variant='outlined'
                        />
                      </StyledFormControl>
                    </RowContainer>
                    <RowContainer
                      width='100%'
                      justify='space-between'
                      align='center'
                    >
                      <StyledFormControl fullWidth>
                        <TextField
                          error={touched.companyName && !!errors.companyName}
                          name={'companyName'}
                          label={'Company Name'}
                          value={values.companyName || ''}
                          onChange={handleChange}
                          color='secondary'
                          type='text'
                          variant='outlined'
                        />
                      </StyledFormControl>
                    </RowContainer>
                    <RowContainer
                      width='100%'
                      justify='space-between'
                      align='center'
                    >
                      <StyledFormControl fullWidth>
                        <TextField
                          error={touched.email && !!errors.email}
                          name={'email'}
                          label={'Email Address'}
                          value={values.email || ''}
                          onChange={handleChange}
                          color='secondary'
                          type='text'
                          variant='outlined'
                        />
                      </StyledFormControl>
                    </RowContainer>
                    <RowContainer
                      width='100%'
                      justify='space-between'
                      align='center'
                    >
                      <StyledFormControl fullWidth>
                        <TextField
                          error={touched.message && !!errors.message}
                          name={'message'}
                          label={'Your Support Message'}
                          multiline
                          rows={6}
                          value={values.message || ''}
                          onChange={handleChange}
                          color='secondary'
                          variant='outlined'
                        />
                      </StyledFormControl>
                    </RowContainer>
                  </Wrapper>
                  <FooterContainer
                    width='100%'
                    justify='space-between'
                    align='center'
                  >
                    <SubmitText>
                      We aim to deal with all support issues within 2 hours of
                      submission. Thank you for your patience.
                    </SubmitText>
                    <SaveButton type='submit'>Submit</SaveButton>
                  </FooterContainer>
                </FormContent>
              </Form>
            )
          }}
        </Formik>
      </MuiThemeProvider>
      <Alert />
    </FormContainer>
  )
}

const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;

  width: 100%;
  border: 1px solid #dddddd;
`
const FooterContainer = styled(RowContainer)`
  padding: 0 32px;

  button {
    margin: 0;
  }
`
const SubmitText = styled.p`
  color: #4c4c4c;
  font-size: 17px;
  padding-right: 32px;
`

import React from "react";
import styled from "styled-components";
import { UserProfile } from "../types";
import { CustomizableButton } from "./Buttons";

interface ProfileBoxProps {
  userInfo: UserProfile;
  appTitle: string;
  versionNo: string;
  handleLogout: () => void;
}

export const ProfileBox = (props: ProfileBoxProps) => {
  return (
    <InfoBox>
      <h3>
        {props.appTitle}
        <strong> (version: {props.versionNo})</strong>
      </h3>
      <div className="user">
        <Avatar>{props.userInfo.profile?.fullName[0] || ""}</Avatar>
        <span>{props.userInfo.profile.fullName || ""}</span>
      </div>
      <div className="metaData">
        <table>
          <tbody>
            <tr>
              <td>User name:</td>
              <td>{props.userInfo.profile.fullName || ""}</td>
            </tr>
            <tr>
              <td>Company name:</td>
              <td>{props.userInfo.companyName || ""}</td>
            </tr>
            <tr>
              <td>Licence number:</td>
              <td>1432353235</td>
            </tr>
            <tr>
              <td>Licence type:</td>
              <td>Enterprise</td>
            </tr>
          </tbody>
        </table>
      </div>
      <LogoutContainer>
        <CustomizableButton onClick={props.handleLogout} elevated width="100px" padding="10px">
          LOGOUT
        </CustomizableButton>
        <span className="agxio-footer">© Agxio {new Date().getFullYear()}</span>
      </LogoutContainer>
    </InfoBox>
  );
};

const InfoBox = styled.div`
  width: 400px;
  background: #ecf0f1;
  padding: 14px;
  color: black;

  h3 {
    margin: 14px;
  }

  .user {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 100px 0 0 100px;
  }

  .user img {
    width: 50px;
    border-radius: 100px;
    border: 1px solid gray;
  }

  .user span {
    vertical-align: middle;
    margin-left: 10px;
    background: white;
    font-weight: bold;
  }

  .metaData {
    padding: 10px;
    background: white;
    color: black;
  }

  .metaData td {
    padding: 10px;
  }

  .logout {
    margin-top: 14px;
  }
`;

const Avatar = styled.div`
  background-color: #757575;
  width: 40px;
  height: 40px;
  color: #303030;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
`;

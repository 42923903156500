import styled from "styled-components";

export interface Props {
  display?: string;
  align?: string;
  justify?: string;
  padding?: string;
  margin?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  flex?: string;
  background?: string;
  overflow?: string;
  border?: string;
}

export const Wrapper = styled.div`
  display: ${(props: Props) => props.display || "static"};
  background: ${(props: Props) => props.background || "unset"};
  align-items: ${(props: Props) => props.align || "center"};
  justify-content: ${(props: Props) => props.justify || "center"};
  padding: ${(props: Props) => props.padding || "0"};
  margin: ${(props: Props) => props.margin || "0"};
  width: ${(props: Props) => props.width || "unset"};
  max-width: ${(props: Props) => props.maxWidth || "unset"};
  min-width: ${(props: Props) => props.minWidth || "unset"};
  height: ${(props: Props) => props.height || "unset"};
  max-height: ${(props: Props) => props.maxHeight || "unset"};
  min-height: ${(props: Props) => props.minHeight || "unset"};
  flex: ${(props: Props) => props.flex || "unset"};
  overflow: ${(props: Props) => props.overflow || "unset"};
  border: ${(props: Props) => props.border || "unset"};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background: ${(props: Props) => props.background || "unset"};
  align-items: ${(props: Props) => props.align || "center"};
  justify-content: ${(props: Props) => props.justify || "center"};
  padding: ${(props: Props) => props.padding || "0"};
  margin: ${(props: Props) => props.margin || "0"};
  width: ${(props: Props) => props.width || "unset"};
  max-width: ${(props: Props) => props.maxWidth || "unset"};
  min-width: ${(props: Props) => props.minWidth || "unset"};
  height: ${(props: Props) => props.height || "unset"};
  max-height: ${(props: Props) => props.maxHeight || "unset"};
  min-height: ${(props: Props) => props.minHeight || "unset"};
  flex: ${(props: Props) => props.flex || "unset"};
  overflow: ${(props: Props) => props.overflow || "unset"};
  border: ${(props: Props) => props.border || "unset"};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(props: Props) => props.background || "unset"};
  align-items: ${(props: Props) => props.align || "center"};
  justify-content: ${(props: Props) => props.justify || "center"};
  padding: ${(props: Props) => props.padding || "0"};
  margin: ${(props: Props) => props.margin || "0"};
  width: ${(props: Props) => props.width || "unset"};
  max-width: ${(props: Props) => props.maxWidth || "unset"};
  min-width: ${(props: Props) => props.minWidth || "unset"};
  height: ${(props: Props) => props.height || "unset"};
  max-height: ${(props: Props) => props.maxHeight || "unset"};
  min-height: ${(props: Props) => props.minHeight || "unset"};
  flex: ${(props: Props) => props.flex || "unset"};
  overflow: ${(props: Props) => props.overflow || "unset"};
  border: ${(props: Props) => props.border || "unset"};
`;

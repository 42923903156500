import React, { ChangeEvent, useState, useEffect } from "react";
import {
  FormControl,
  Card,
  CardMedia,
  CardActions,
  Button,
  Grid,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components";
import { SvgIcon } from "./SvgIcon";

export interface FileUploadProps {
  baseUrl: string;
  files: any[];
  singleFile: boolean;
  isImage: boolean;
  accept?: string;
  label?: string;
  uploadFile(file: File): Promise<any>;
  cancelUploadFile(file: File): void;
  onFileRemoved(filename: string): void;
  onChangeFiles(uploadedFiles: any[]): void;
}

const getInitialState = (initialFiles?: any[]) =>
  initialFiles == null
    ? []
    : initialFiles.map(
        fileInput =>
          ({
            fileInput,
            isLoading: false,
            isUploaded: false,
            isCancelled: false,
          } as {
            fileInput?: any;
            file: File;
            isLoading: boolean;
            isUploaded: boolean;
            isCancelled: boolean;
          }),
      );

let nInstances = 0;

const FileUpload = (props: FileUploadProps) => {
  const fileAccept = props.accept || (props.isImage ? "image/*" : "*");
  const [files, setFiles] = useState(() => {
    const initialValue = getInitialState(props.files);
    return initialValue;
  });

  useEffect(() => {
    setFiles(getInitialState(props.files));
  }, [props.files.length > 0]);

  const canUploadMore = props.singleFile !== true || files.filter(file => file.isCancelled !== true).length === 0;

  nInstances += 1;
  const inputId = `file-input-${nInstances}`;

  const uploadFiles = (filesToUpload: File[]) => {
    const nPreImages = files.length;

    setFiles(files => [
      ...files,
      ...filesToUpload.map(fileToUpload => ({
        file: fileToUpload,
        isLoading: true,
        isUploaded: false,
        isCancelled: false,
      })),
    ]);

    filesToUpload.forEach((fileToUpload: File, index: number) => {
      const uploadingIndex = nPreImages + index;
      props
        .uploadFile(fileToUpload)
        .then(result => {
          if (result === undefined) {
            return;
          }

          setFiles(prevState => {
            if (prevState[uploadingIndex].isCancelled) {
              return prevState;
            }
            const newState = [
              ...prevState.map((item, index) => {
                if (index === uploadingIndex) {
                  return {
                    ...item,
                    fileInput: `${props.baseUrl}/files/${result.data.uploadFile.filename}`,
                    isLoading: false,
                    isUploaded: true,
                  };
                } else {
                  return item;
                }
              }),
            ];
            props.onChangeFiles(newState.map(file => file.fileInput!));

            return newState;
          });
        })
        .catch(_ => {
          const newImages = [
            ...files,
            ...files.map((item, index) =>
              index === uploadingIndex
                ? {
                    ...item,
                    isLoading: false,
                    isUploaded: false,
                    isCancelled: false,
                  }
                : item,
            ),
          ];

          setFiles(newImages);
        });
    });
  };
  return (
    <FormControl margin="normal" key={inputId}>
      <Grid container={true} alignItems="center" justify="space-between">
        {files.map((theFile, fileIndex) => {
          if (theFile.isCancelled) {
            return null;
          } else if (theFile.isLoading || theFile.fileInput == null) {
            return (
              <Grid key={`images-${fileIndex}`} item={true}>
                <CardContainer>
                  <CustomCardContent>
                    <CircularProgress color="secondary" />
                  </CustomCardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        props.cancelUploadFile(theFile.file);
                        const newImages = files.map((img, index) =>
                          index === fileIndex
                            ? {
                                ...files[index],
                                isLoading: false,
                                isUploaded: false,
                                isCancelled: true,
                              }
                            : img,
                        );
                        setFiles(newImages);
                      }}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                </CardContainer>
              </Grid>
            );
          }

          const source = theFile.fileInput || "/assets/image_placeholder.png";

          return (
            <Grid key={`images-${fileIndex}`} item={true}>
              <CardContainer>
                <React.Fragment>
                  {props.isImage ? (
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "contain",
                        height: 80,
                      }}
                      image={source}
                    />
                  ) : (
                    <Typography style={{ height: 80 }}>{theFile.fileInput.file}</Typography>
                  )}
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      size="small"
                      variant="contained"
                      component="span"
                      onClick={() => {
                        props.onFileRemoved(theFile.fileInput);
                        const imagesWithoutRemoved = files.filter((_, index) => index !== fileIndex);
                        setFiles(imagesWithoutRemoved);
                        props.onChangeFiles(imagesWithoutRemoved.map(img => img.fileInput!));
                      }}
                    >
                      Remove
                    </Button>
                  </CardActions>
                </React.Fragment>
              </CardContainer>
            </Grid>
          );
        })}
        {canUploadMore && (
          <Grid item={true}>
            <input
              accept={fileAccept}
              style={{ display: "none" }}
              id={inputId}
              multiple={props.singleFile !== true}
              type="file"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  uploadFiles(Array.from(event.target.files));
                }
              }}
            />
            <label htmlFor={inputId}>
              <Button variant="contained" component="span">
                <SvgIcon name="Upload" globalUsage={true} />
                {props.label ? props.label : "Upload"}
              </Button>
            </label>
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
};

const CardContainer = styled(Card)`
  max-width: 120px;
  margin-top: 12px;
`;

const CustomCardContent = styled(CardContent)``;

export { FileUpload };

import React, { ReactElement } from "react";
import styled from "styled-components";

import { Header } from "./form";
import { Wrapper } from "./FlexBox";

interface AgxioBoxProps {
  header: string | ReactElement;
  children: ReactElement;
  display?: string;
  padding?: string;
  margin?: string;
  background?: string;
  overflow?: string;
  flex?: string;
}

export const AgxioBox = (props: AgxioBoxProps) => {
  return (
    <Container
      display={props.display}
      padding={props.padding}
      margin={props.margin}
      background={props.background}
      overflow={props.overflow}
      flex={props.flex}
    >
      <Header>{props.header}</Header>
      <ContentContainer>{props.children}</ContentContainer>
    </Container>
  );
};

const Container = styled(Wrapper)`
  height: 100%;
  width: 100%;
  display: block;
`;

const ContentContainer = styled.div`
  height: calc(100% - 42px);
`;

import styled from "styled-components";

interface Props {
  color?: string;
  fontColor?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  borderColor?: string;
  elevated?: boolean;
  padding?: string;
  margin?: string;
}

export const CustomizableButton = styled.button`
  background-color: ${(props: Props) => props.color || "white"};
  padding: ${(props: Props) => props.padding || "5px 2px"};
  margin: ${(props: Props) => props.margin || "0"};
  width: ${(props: Props) => props.width || "unset"};
  height: ${(props: Props) => props.height || "unset"};
  color: ${(props: Props) => props.fontColor || "black"};
  border: ${(props: Props) => (props.borderColor ? `solid 1px ${props.borderColor}` : "none")};
  box-shadow: ${(props: Props) =>
    props.elevated
      ? "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
      : "none"};
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.5s;
  font-size: ${(props: Props) => props.fontSize || "14px"};
  :disabled {
    color: darkslategray;
    cursor: initial;
  }
  :enabled {
    transition: all 0.5s;
    &:hover {
      background-color: #b2b2b2;
    }
  }
`;

export const PrimaryButton = styled(CustomizableButton)`
  width: ${(props: Props) => props.width || "100%"};
  padding: ${(props: Props) => props.padding || "15px 2px"};
  margin: ${(props: Props) => props.margin || "10px"};
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  :disabled {
    background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%);
    color: darkslategray;
    cursor: initial;
  }
  :enabled {
    transition: all 0.5s;
    :hover {
      opacity: 0.6;
    }
  }
  color: white;
  border-radius: 10px;
`;

export const SecondaryButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: opacity 0.5s;
  border: none;
  background-color: #d9f3e6;
  color: #047439;
  width: unset;
  padding: 5px 15px;
  margin: 0;
  font-size: 15px;
  :not(:disabled) {
    cursor: pointer;
  }
  :hover:not(:disabled) {
    opacity: 0.5;
  }
  :disabled {
    background-color: #e5e5e5;
    color: #000;
  }
`;

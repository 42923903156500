import React, { useState, useEffect } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import styled from "styled-components";
import { RowContainer } from "./FlexBox";
import { PrimaryButton } from "./Buttons";

interface Props {
  data: any[];
  headers: any[];
  selectedRow?: string | number;
  idColumn?: string;
  onSelectItem(row: any): void;
  hasMore?: boolean;
  onFetchMore?: () => void;
}

const getSort = (sort: string) => {
  if (sort === "" || sort === "asc") {
    return "desc";
  } else {
    return "asc";
  }
};

const desc = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const removedTypeProp = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([key]) => key !== "__typename"));

export const ListTable = (props: Props) => {
  const [sort, setSort] = useState({ column: -1, sort: "" });
  const [data, setData] = useState(props.data);
  const [selected, setSelected] = useState();
  useEffect(() => setData(props.data), [props.data]);

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            {props.headers.map((item, key) => (
              <th
                key={key}
                style={{ width: item.width }}
                onClick={() => {
                  const order = sort.column !== key ? "desc" : getSort(sort.sort);
                  setSort({ column: key, sort: order });
                  if (order === "asc") {
                    data.sort((a, b) => desc(a, b, item.value));
                  } else {
                    data.sort((a, b) => -desc(a, b, item.value));
                  }
                  setData(data);
                }}
              >
                <div>
                  <span>{item.title}</span>
                  {sort.column === key && sort.sort === "asc" && <ArrowUpward style={{ fontSize: "16px" }} />}
                  {sort.column === key && sort.sort === "desc" && <ArrowDownward style={{ fontSize: "16px" }} />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item: any, key: number) => (
              <tr
                key={key}
                style={{
                  backgroundColor:
                    selected === (props.idColumn ? item[props.idColumn] : key) ? "rgba(149, 164, 180, 0.34)" : "",
                }}
                onClick={() => {
                  setSelected(props.idColumn ? item[props.idColumn] : key);
                  props.onSelectItem(removedTypeProp(item));
                }}
              >
                {props.headers.map((header: any, hkey: number) => (
                  <td key={hkey}>{item[header.value]}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      {data && props.hasMore && (
        <RowContainer width="100%" height="50px" padding="35px 0" align="center" justify="center">
          <PrimaryButton width="20%" onClick={() => props.onFetchMore && props.onFetchMore()}>
            Load More Results
          </PrimaryButton>
        </RowContainer>
      )}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;

  th {
    background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%), #000000;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    color: white;
    text-align: left;
    cursor: pointer;
    div {
      margin: 0 16px;
      display: flex;
      align-items: center;
    }
    position: sticky;
    top: 0;
    padding: 5px;
    height: 40px;
    font-weight: normal;
  }
  th:not(:first-child) > div {
    text-align: left;
  }
  tr > :not(:first-child) {
    text-align: left;
  }
  td {
    color: white;
    border: 1px solid #4e4e4e;
    text-align: left;
    padding: 4px 20px;
    height: 50px;
    align-items: center;
    cursor: pointer;
  }
  tr:nth-child(even) {
    background: #2b2b2b;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "../Assets/icons/x.svg";
import { ReactComponent as TickIcon } from "../Assets/icons/tick.svg";

interface Props {
  id: string;
  onCheckboxClicked(id: string, checked: boolean): Promise<boolean>;
  labelOne?: string;
  labelTwo?: string;
  height: string;
  checked: boolean;
  marginRight?: string;
}
const ToggleButton = (props: Props) => {
  const [on, setOn] = useState<boolean>(props.checked);

  return (
    <Container
      height={props.height}
      isOn={on}
      style={{ marginRight: props.marginRight }}
      onClick={async e => {
        const oldOn = on;
        setOn(!on);
        const result = await props.onCheckboxClicked(props.id, !on);
        if (!result) {
          setOn(oldOn);
        }
      }}
    >
      {on ? props.labelOne : props.labelTwo}
      <Circle height={props.height} isOn={on}>
        {on ? <TickIcon /> : <XIcon />}
      </Circle>
    </Container>
  );
};

interface ContainerProps {
  height?: string;
  isOn?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: ${(props: ContainerProps) => props.height};
  position: relative;
  border-radius: 15px;
  border: 2px solid #000000;
  background-color: ${(props: ContainerProps) => (props.isOn ? "#19A355" : "#414141")};
  color: white;
  align-items: center;
  justify-content: ${(props: ContainerProps) => (props.isOn ? "flex-start" : "flex-end")};
  padding: ${(props: ContainerProps) => (props.isOn ? "5px 36px 5px 10px" : "5px 10px 5px 36px")};
  transition: padding 0.2s;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  line-height: 11px;
`;

const Circle = styled.div`
  position: absolute;
  left: ${(props: ContainerProps) => (props.isOn ? `calc(100% - ${props.height})` : "0")};
  transition: 0.2s;
  cursor: pointer;
  height: ${(props: ContainerProps) => props.height};
  width: ${(props: ContainerProps) => props.height};
  border: black solid 2px;
  border-radius: ${(props: ContainerProps) => `calc(${props.height} / 2)`};
  background-color: ${(props: ContainerProps) => (props.isOn ? "white" : "#FA5656")};
  color: ${(props: ContainerProps) => (props.isOn ? "#19A355" : "white")};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12.5px;
  text-align: center;
`;

ToggleButton.defaultProps = {
  labelOne: "Yes",
  labelTwo: "No",
  checked: false,
};

export { ToggleButton };

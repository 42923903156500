import styled from "styled-components";

interface MainContainerProps {
  height?: string;
  padding?: string;
}

export const MainContainer = styled.div`
  width: 100%;
  height: ${(props: MainContainerProps) => (props.height ? props.height : "100%")};
  display: flex;
  flex-direction: column;
  ${(props: MainContainerProps) => props.padding && { padding: props.padding }}
`;

import React, { useState, useEffect } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import styled from "styled-components";
import { capitalize } from "@material-ui/core";

export interface Header {
  title: string;
  key: string;
  rendrer?(row: any): JSX.Element;
}

interface Props {
  data: any[];
  headers: Header[];
  hasMore?: boolean;
  noborder?: boolean;
  noHeader?: boolean;
  tableStyle?: string;
  uniqeID?: string;
  selectedRow?: any;
  onSelect?: (item: any) => void;
  onFetchMore?: () => void;
}

const getSort = (sort: string) => {
  if (sort === "" || sort === "asc") {
    return "desc";
  } else {
    return "asc";
  }
};

const desc = (a: any, b: any, orderBy: string) => {
  let f = b[orderBy];
  let s = a[orderBy];
  if (orderBy === "date") {
    const fParts = b[orderBy].split("/");
    const sParts = a[orderBy].split("/");
    f = new Date(fParts[2], fParts[1], fParts[0]);
    s = new Date(sParts[2], sParts[1], sParts[0]);
  }
  if (f < s) {
    return -1;
  }
  if (f > s) {
    return 1;
  }
  return 0;
};

export const DataTable = (props: Props) => {
  let { headers } = props;
  const [sort, setSort] = useState({ column: -1, sort: "" });
  const [data, setData] = useState(props.data);
  let selectedItem = (props.selectedRow && !!props.uniqeID && props.selectedRow[props.uniqeID]) || -1;
  const [selected, setSelected] = useState(selectedItem);

  useEffect(() => {
    setSelected(selectedItem);
  }, [props.selectedRow]);

  useEffect(() => {
    const d = props.data;
    if (sort.sort === "asc") {
      d.sort((a, b) => desc(a, b, headers[sort.column].key));
    } else if (sort.sort === "desc") {
      d.sort((a, b) => -desc(a, b, headers[sort.column].key));
    }
    setData(d);
  }, [props.data, sort]);
  return (
    <TableContainer
      noborder={props.noborder || false}
      onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (data && data.length > 0 && props.hasMore) {
          const dataTable = e.currentTarget;
          const endOfScroll = dataTable.scrollHeight - dataTable.clientHeight - dataTable.scrollTop < 10;
          if (endOfScroll && props.onFetchMore) {
            props.onFetchMore();
          }
        }
      }}
    >
      <Table tableStyle={props.tableStyle}>
        {!props.noHeader && (
          <thead>
            <tr>
              {headers &&
                headers.map((item: Header, key: number) => (
                  <th
                    key={String(key)}
                    onClick={() => {
                      const order = sort.column !== key ? "desc" : getSort(sort.sort);
                      setSort({ column: key, sort: order });
                      if (order === "asc") {
                        data.sort((a, b) => desc(a, b, item.key));
                      } else {
                        data.sort((a, b) => -desc(a, b, item.key));
                      }
                      setData(data);
                    }}
                  >
                    <div>
                      <p>{capitalize(item.title)}</p>
                      {sort.column === key && sort.sort === "asc" && <ArrowUpward style={{ fontSize: "16px" }} />}
                      {sort.column === key && sort.sort === "desc" && <ArrowDownward style={{ fontSize: "16px" }} />}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data &&
            data.map((item: any, key: number) => (
              <TableRow
                onClick={_ => {
                  !!props.uniqeID && setSelected(item[props.uniqeID]);
                  props.onSelect && props.onSelect(item);
                }}
                key={String(key)}
                selected={!!props.uniqeID && selected == item[props.uniqeID]}
                selectable={!!props.uniqeID}
              >
                {headers &&
                  headers.map((h: Header, k: number) =>
                    h.rendrer ? (
                      <td key={String(k)}>{h.rendrer(item)}</td>
                    ) : (
                      <td key={String(k)}>
                        <TruncatedDiv>
                          <span>{item[h.key]}</span>
                        </TruncatedDiv>
                      </td>
                    ),
                  )}
              </TableRow>
            ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};
const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: black;
  border: ${(props: { noborder: boolean }) => !props.noborder && "1px solid #616161"};
  overflow: auto;
  border-radius: 0;
`;

const Table = styled.table`
  overflow-x: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  P {
    margin: 0;
    width: 100%;
  }
  th {
    background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%), #000000;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    color: white;
    text-align: left;
    cursor: pointer;
    position: sticky;
    top: 0;
    padding: 5px;
    height: 42px;
    width: 100px;
    div {
      margin: 0 16px;
      display: flex;
      align-items: center;
    }
  }
  tr:nth-child(even) {
    background: #2b2b2b;
  }
  td {
    color: white;
    border: 1px solid #4e4e4e;
    padding: 4px 20px;
    height: 40px;
    align-items: center;
  }

  ${(props: { tableStyle?: string }) => props.tableStyle}
`;

const TruncatedDiv = styled.div`
  display: flex;
  span {
    flex: 1;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface TableRowProps {
  selected: boolean;
  selectable: boolean;
}

const TableRow = styled.tr`
  background: ${(props: TableRowProps) => (props.selected ? "#0f9349 !important" : "unset")};
  cursor: ${(props: TableRowProps) => (props.selectable ? "pointer" : "default")};
`;

import React from "react";
import styled from "styled-components";
import { RowContainer } from "./FlexBox";

export const SlideMenu = () => {
  return (
    <Container>
      <RowContainer justify="flex-start" padding="5px 10px">
        <i
          className="fa fa-asterisk"
          style={{ color: "white", fontSize: 20, padding: "1%" }}
        />
        <span>{process.env.REACT_APP_TITLE}</span>
      </RowContainer>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  width: 288px;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 3vh;
  background-color: black;
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const Divider = styled.hr`
  margin-left: 2%;
  border-color: green;
  border-width: 2px;
  margin-right: 5%;
`;

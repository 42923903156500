import styled, { css } from "styled-components";
import { CircularProgress, Button, FormControl, createMuiTheme, Typography } from "@material-ui/core";
import { ColumnContainer } from "./FlexBox";

export const Styles = css`
  border: 1px solid rgb(97, 97, 97);
  background-color: black;
`;

export const CircularLoading = styled(CircularProgress)`
  position: absolute;
  bottom: 2%;
  right: 1%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormBody = styled.div`
  margin-bottom: 32px;
`;

export const FormContainer = styled(ColumnContainer)`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  ${Styles}
`;

export const Header = styled.div`
  width: 100%;
  height: 42px;
  background: ${(props: { start?: string; end?: string }) =>
    props.start && props.end
      ? `linear-gradient(180deg, ${props.start} 0%, ${props.end} 100%)`
      : `linear-gradient(180deg, #19a355 0%, #056e34 100%)`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 16px;
  color: white;
  p {
    font-weight: 450;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: -0.02em;
  }
`;
interface FormContentProps {
  padding?: string;
  borderRadius?: string;
}
export const FormContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 98%;
  background: #fff;
  padding: ${(props: FormContentProps) => props.padding || "0"};
  border-radius: ${(props: FormContentProps) => props.borderRadius || "0"};
`;

export const FormTitle = styled(Typography)`
  color: #0f9349;
  font-weight: bold;
  border-bottom: 3px solid #0f9349;
  width: fit-content;
  font-size: 16px;
`;

export const FormButtonControl = styled(FormControl)`
  width: 33%;
`;

interface ListContainerProps {
  width?: string;
  height?: string;
  margin?: string;
}

export const ListContainer = styled(ColumnContainer)`
  width: ${(props: ListContainerProps) => props.width || "58%"};
  height: ${(props: ListContainerProps) => props.height || "100%"};
  margin: ${(props: ListContainerProps) => props.margin || "unset"};
  background-color: black;
  overflow-x: scroll;
  ${Styles}
`;

interface FormFooterProps {
  padding?: string;
  margin?: string;
  background?: string;
}

export const FormFooter = styled.div`
  background: ${(props: FormFooterProps) => props.background || "#fff"};
  position: sticky;
  bottom: 0;
  padding: ${(props: FormFooterProps) => (props.padding ? props.padding : "0")};
  margin: ${(props: FormFooterProps) => (props.margin ? props.margin : "0")};
`;

export const RoundedFormFooter = styled(FormFooter)`
  background: #f5f5f5;
  border-radius: 0px 0px 20px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const CancelButton = styled.button`
  background: linear-gradient(180deg, #9f9f9f 0%, #616161 100%), #000000;
  border: 1px solid #595959;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(223, 223, 223, 0.7);
  border-radius: 10px;
  color: white;
  height: 48px;
  min-width: 120px;
  :enabled {
    transition: all 0.5s;
    :hover {
      opacity: 0.75;
    }
  }
`;

export const SaveButton = styled.button`
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  border: 1px solid #076b32;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(183, 240, 208, 0.7);
  border-radius: 10px;
  color: white;
  height: 48px;
  min-width: 120px;
  margin: 0 32px;
  :enabled {
    transition: all 0.5s;
    :hover {
      opacity: 0.75;
    }
  }
`;

export const DeleteButton = styled.button`
  background: linear-gradient(180deg, #d32030 0%, #ad1220 100%);
  border: 1px solid #6c2022;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgba(241, 84, 95, 0.7);
  border-radius: 10px;
  color: white;
  height: 48px;
  min-width: 120px;
  margin: 0 32px;
  :enabled {
    transition: all 0.5s;
    :hover {
      opacity: 0.75;
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin: 16px 30px 4px 30px;
`;

export const RowFormControl = styled(FormControl)`
  width: 100%;
  flex: ${({ flex }: { flex?: number }) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
`;

export const ModalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#056e34",
    },
    secondary: {
      main: "#000",
    },
    background: {
      paper: "#FFF",
    },
  },
  typography: {},
});

import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as SaveIcon } from "../Assets/icons/save.svg";
import { ReactComponent as QuestionIcon } from "../Assets/icons/question.svg";
import { ReactComponent as SettingsIcon } from "../Assets/icons/settings.svg";
import { ReactComponent as PrinterTag } from "../Assets/icons/print.svg";
import { ReactComponent as UploadTag } from "../Assets/icons/upload.svg";

type IconTypes = "SaveIcon" | "QuestionIcon" | "SettingsIcon" | "PrinterTag" | "Upload";

interface IconProps {
  name: IconTypes;
  globalUsage?: boolean;
  onClick?: (e: any) => void;
}

const renderIcon = (name: IconTypes) => {
  switch (name) {
    case "SaveIcon":
      return <SaveIcon />;
    case "QuestionIcon":
      return <QuestionIcon />;
    case "SettingsIcon":
      return <SettingsIcon />;
    case "PrinterTag":
      return <PrinterTag />;
    case "Upload":
      return <UploadTag />;
    default:
      return <React.Fragment />;
  }
};

export const SvgIcon = (props: IconProps) => {
  return (
    <IconContainer {...props} onClick={props.onClick ? props.onClick : () => {}}>
      {renderIcon(props.name)}
    </IconContainer>
  );
};

const CommonStyles = css`
  left: 1202px;
  top: 40px;
  margin: 0 4px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  :hover,
  :hover svg path {
    background: #0f9349;
    fill: #fff;
    cursor: pointer;
    border: 2px solid #0f9349;
  }
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  ${(props: IconProps) => (!props.globalUsage ? CommonStyles : {})};
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { ReactNode } from "react";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../Assets/icons/close-gray.svg";

interface AgxioModalProps {
  handleToggle(isOpen: boolean): void;
  open: boolean;
  title: string;
  children: ReactNode;
  showFooter?: boolean;
  width?: string;
}

export const AgxioModal = (props: AgxioModalProps) => {
  return (
    <Dialog
      PaperProps={{ style: { backgroundColor: "unset" }, elevation: 0 }}
      onBackdropClick={_ => props.handleToggle(false)}
      open={props.open}
      maxWidth="xl"
    >
      <Header>
        <p>{props.title}</p>
        <StyledCloseIcon onClick={() => props.handleToggle(false)} />
      </Header>
      <DialogContent width={props.width}>{props.children}</DialogContent>
      {props.showFooter && <Footer></Footer>}
    </Dialog>
  );
};

const StyledCloseIcon = styled(CloseIcon)`
  :hover {
    cursor: pointer;
  }
  :hover path {
    fill: #fff;
  }
`;

const DialogContent = styled.div`
  ${(props: { width?: string }) => (!props.width ? "min-width: 600px" : `width: ${props.width}`)};
  background: #fff;
  color: #000;
  border-radius: 0px 0px 20px 20px;
  overflow: auto;
`;

export const Header = styled.div`
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  border-radius: 20px 20px 0px 0px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px;
  color: white;

  p {
    font-weight: 450;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: -0.02em;
  }
`;

const Footer = styled.div`
  height: 42px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: solid 3px #c9c9c9;
  p {
    margin: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #131313;
  }
`;

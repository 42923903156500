import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

export const Loading = () => {
  return (
    <Container>
      <CircularProgress color="secondary" />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 32px;
  border-radius: 20px;
  background: linear-gradient(180deg, #19a355 0%, #056e34 100%);
  box-shadow: 0px 8px 12px #000000;
  opacity: 0.7;
  elevation: 10px;
  transform: translate(-50%, -50%);
`;

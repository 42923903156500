import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import styled from "styled-components";
import { ReactComponent as DangerIcon } from "../Assets/icons/danger.svg";
import { ReactComponent as CheckIcon } from "../Assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "../Assets/icons/close.svg";
import { ReactComponent as InfoIcon } from "../Assets/icons/info.svg";

interface State {
  open: boolean;
  alertType: AlertType;
  autoHideDuration: number;
  title: string;
  message: string;
  position?: AlertPosition;
}
type AlertPosition = {
  horizontal: "center" | "left" | "right";
  vertical: "bottom" | "top";
};
type AlertType = "error" | "success" | "info" | "warning";

let ErrorAlert: (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => void;
let WarningAlert: (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => void;
let SuccessAlert: (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => void;
let InfoAlert: (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => void;

export class Alert extends Component<{}, State> {
  state: State = {
    open: false,
    autoHideDuration: 4500,
    title: "",
    message: "",
    position: { vertical: "bottom", horizontal: "right" },
    alertType: "warning",
  };

  componentDidMount = () => {
    ErrorAlert = (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => {
      this.showAlert("error", message, title, autoHideDuration, position);
    };
    WarningAlert = (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => {
      this.showAlert("warning", message, title, autoHideDuration, position);
    };
    SuccessAlert = (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => {
      this.showAlert("success", message, title, autoHideDuration, position);
    };
    InfoAlert = (message: string, title?: string, autoHideDuration?: number, position?: AlertPosition) => {
      this.showAlert("info", message, title, autoHideDuration, position);
    };
  };

  showAlert = (
    alertType: AlertType,
    message: string,
    title?: string,
    autoHideDuration?: number,
    position?: AlertPosition,
  ) => {
    this.setState({
      open: true,
      title: title ? title : "",
      message,
      alertType,
    });
    autoHideDuration && this.setState({ autoHideDuration });
    position && this.setState({ position });
  };

  getIcon = (alertType: AlertType) => {
    switch (alertType) {
      case "error":
      case "warning":
        return <DangerIcon />;
      case "info":
        return <InfoIcon />;
      case "success":
        return <CheckIcon />;
    }
  };

  hide = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, alertType, title, message, autoHideDuration, position } = this.state;
    return (
      <Snackbar open={open} anchorOrigin={position} autoHideDuration={autoHideDuration} onClose={this.hide}>
        <AlertContainer alertType={alertType}>
          <IconContainer alertType={alertType}>{this.getIcon(alertType)}</IconContainer>
          <Title alertType={alertType}>{title}</Title>
          <Message alertType={alertType}>{message}</Message>
          <StyledCloseIcon onClick={this.hide} />
        </AlertContainer>
      </Snackbar>
    );
  }
}

export { ErrorAlert, SuccessAlert, InfoAlert, WarningAlert };

interface StyleProps {
  alertType: AlertType;
}

const IconContainer = styled.div`
  width: 62px;
  height: 54px;
  margin-left: -2px;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: StyleProps) => {
    switch (props.alertType) {
      case "error":
        return "#D32030";
      case "success":
        return "#0F9349";
      case "warning":
        return "#FEE149";
      case "info":
        return "#0C6CC4";
    }
  }};
`;

const AlertContainer = styled.div`
  background: ${(props: StyleProps) => {
    switch (props.alertType) {
      case "error":
        return "#FFF1F1";
      case "success":
        return "#DEFFED";
      case "warning":
        return "#FFFADF";
      case "info":
        return "#EBF5FF";
    }
  }};
  border: 2px solid
    ${(props: StyleProps) => {
      switch (props.alertType) {
        case "error":
          return "#FFF1F1";
        case "success":
          return "#DEFFED";
        case "warning":
          return "#FFFADF";
        case "info":
          return "#EBF5FF";
      }
    }};
  height: 54px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-width: 250px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-left: 16px;
  color: ${(props: StyleProps) => {
    switch (props.alertType) {
      case "error":
        return "#D32030";
      case "success":
        return "#0F9349";
      case "warning":
        return "#7E6D13";
      case "info":
        return "#0C6CC4";
    }
  }};
`;

const Message = styled.p`
  font-weight: 200;
  font-size: 18px;
  line-height: 23px;
  margin-left: 16px;
  color: ${(props: StyleProps) => {
    switch (props.alertType) {
      case "error":
        return "#d71f28";
      case "success":
        return "#0F9349";
      case "warning":
        return "#B19916";
      case "info":
        return "#6B9BC7";
    }
  }};
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin: 24px;
  :hover path {
    fill: #000;
  }
`;

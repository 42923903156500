import React from "react";
import styled from "styled-components";

interface Props {
  style?: React.CSSProperties;
  selectorStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  options: any[];
  label?: string;
  onSelect: (selected: any, meta?: any) => void;
  selected?: any;
}

export const DropDown = (props: Props) => {
  return (
    <Container style={props.style}>
      {props.label && <Label style={props.labelStyle}>{props.label}</Label>}
      <Selector
        onChange={event => {
			const meta = event.target.options[event.target.selectedIndex].getAttribute("data-meta") || undefined;
			props.onSelect((event.target as any).value, meta)}
		}
        style={props.selectorStyle}
        value={props.selected}
      >
        {props.options.map((item: any, key: number) => (
          <option className={key === 0 ? "option" : ""} key={String(key)} value={item.value} data-meta={item.metaData}>
            {item.title}
          </option>
        ))}
      </Selector>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Label = styled.label`
  color: white;
  font-size: 16px;
  white-space: nowrap;
  font-weight: normal;
`;

const Selector = styled.select`
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #616161;
  background-color: white;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 38px;
  border-radius: 10px;
  padding: 8px 13px;
  margin-left: 10px;
  .option {
    background-color: white;
    color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :focus {
    outline: none;
  }
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 7L13.0622 0.205882H0.937822L7 7Z' fill='%235A5A5A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;
